import Tools from './../components/tools';
import { tns } from './../../../node_modules/tiny-slider/src/tiny-slider';
export default class Page {
    constructor() {
        // Page
        this.popin();
        this.faq();
        this.reaction();
        this.youtube();
        this.sound();
        this.video();
    }

    initModule() {
        let applyResizeTimeout = null;
        onresize = (event) => { // To avoid resizing on each step of the resize causing perfect module to crash
            if (applyResizeTimeout) {
                clearTimeout(applyResizeTimeout);
            }
            applyResizeTimeout = setTimeout(() => {
                YMK.close()
                initYMK();
            }, 1000);

        };
        function popinQuizz(time) {
            console.log("call popinquizz");
            setTimeout(() => { // Moved it here are it's the only starting point after UI completely load I have from perfect
                document.getElementById('divpopup').style.visibility = 'visible';
                console.log("visible popinquizz");
            }, time * 1000);
        }

        function onModuleLoad() {
            let removedUI = false;
            setInterval(() => {
                if (!document.querySelector("#YMK-module-iframe"))
                    return;
                let crappyDownloadButton = document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="download photo to device"]')[0];//.querySelector("#YMK-module-body > div > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(3) > div:nth-child(6) > button:nth-child(5) > img");
                // console.log(crappyDownloadButton);
                // Replaces perfect crappy download button with the copied "clean" on present in the page
                try {
                    if (crappyDownloadButton) {
                        // crappyDownloadButton.replaceWith(document.querySelector("body > div > button"))
                        crappyDownloadButton.style.visibility = 'hidden'
                    }
                }
                catch (_) { }

                let elementsToRemoveFromPerfectTemplate = [
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="go back to try it on screen"]')[0],
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="exit virtual try on"]')[0],
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="remove try on item"]')[0],
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="start compare mode"]')[0],
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="download photo to device"]')[0],

                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="zoom in"]')[0],
                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="zoom out"]')[0],

                    document.querySelector("#YMK-module-iframe").contentWindow.document.querySelectorAll('[aria-label="take a photo to photo mode"]')[0],
                ];

                elementsToRemoveFromPerfectTemplate.forEach((el) => {
                    if (el) {
                        // console.log("Removed element");
                        // el.remove();
                        el.style.visibility = 'hidden'

                        if (!removedUI)
                            popinQuizz(20);
                        removedUI = true;
                    }
                });
            }, 500);
        };
        function initYMK() {
            // If you need to set other resolution you need to set the args.disableResolutionCheck. But please note that the YMK module may not work as expected.
            YMK.init({
                disableResolutionCheck: true,
                height: document.querySelector('.app__popinContainer').offsetHeight,
                width: document.querySelector('.app__popinContainer').offsetWidth,
                downloadPhotoMode: "direct"
            })
            YMK.open(true);
            onModuleLoad();
        }
        // window.ymkAsyncInit = initYMK;
        initYMK();
    }

    popin() {
        const btns = document.querySelectorAll('.app__cta, .application__cta');
        const elt = document.querySelector('.app__popin');

        if (btns.length > 0 && elt) {
            btns.forEach((btn) => {
                btn.addEventListener('click', () => {
                    elt.classList.add('js-visible');
                    this.initModule();
                });
            });
        }

        if(elt) {
            elt.querySelector('.app__close').addEventListener('click', () => {
                elt.querySelector('.app__popin iframe').remove();
                elt.classList.remove('js-visible');
            });
        }
    }

    faq() {
        const elts = document.querySelectorAll('.faq__item');
        if (elts.length > 0) {
            elts.forEach((elt) => {
                elt.querySelector('.faq__title').addEventListener('click', () => {
                    if (!elt.classList.contains('js-open')) {
                        if (document.querySelector('.faq .faq__item.js-open')) {
                            Tools.autoToZero(document.querySelector('.faq .faq__item.js-open .faq__wrapper'));
                            document.querySelector('.faq .faq__item.js-open').classList.remove('js-open');
                        }
                        elt.classList.add('js-open');
                        Tools.zeroToAuto(elt.querySelector('.faq__wrapper'));
                    } else {
                        elt.classList.remove('js-open');
                        Tools.autoToZero(elt.querySelector('.faq__wrapper'));
                    }
                });
            });
        }
    }

    reaction() {
        if(document.querySelector('.reaction__slider')) {
            tns({
                container: document.querySelector('.reaction__slider'),
                items: 1,
                slideBy: 1,
                autoplay: false,
                gutter: 20,
                loop: false,
                mouseDrag: false,
                nav: false,
                speed: 850,
                autoHeight: true,
                controlsPosition: 'bottom',
                controlsText: ['<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="40" y="0.00390625" width="40" height="40" rx="20" transform="rotate(90 40 0.00390625)" fill="#283333"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.8287 19.5039L29 19.5039L29 20.5039L13.8287 20.5039C14.8482 21.3289 15.5 22.5903 15.5 24.0039L14.5 24.0039C14.5 22.0711 12.9333 20.5042 11.0006 20.5039C11.0004 20.5039 11.0002 20.5039 11 20.5039L11 19.5039C11.0006 19.5039 11.0012 19.5039 11.0018 19.5039C12.934 19.5029 14.5 17.9363 14.5 16.0039L15.5 16.0039C15.5 17.4175 14.8482 18.6789 13.8287 19.5039Z" fill="white"/></svg>', '<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="40.0039" width="40" height="40" rx="20" transform="rotate(-90 0 40.0039)" fill="#283333"/><path fill-rule="evenodd" clip-rule="evenodd" d="M26.1713 20.5039L11 20.5039L11 19.5039L26.1713 19.5039C25.1518 18.6789 24.5 17.4175 24.5 16.0039L25.5 16.0039C25.5 17.9367 27.0667 19.5036 28.9994 19.5039C28.9996 19.5039 28.9998 19.5039 29 19.5039L29 20.5039C28.9994 20.5039 28.9988 20.5039 28.9982 20.5039C27.066 20.5049 25.5 22.0715 25.5 24.0039L24.5 24.0039C24.5 22.5903 25.1518 21.3289 26.1713 20.5039Z" fill="white"/></svg>'],
            });
        }
    }

    youtube() {
        const elts = document.querySelectorAll('[data-youtube]');
        const popin = document.querySelector('.youtube');

        if (elts.length > 0 && popin) {
            elts.forEach((elt) => {
                elt.addEventListener('click', () => {
                    popin.querySelector('.youtube__iframe iframe').src = `https://www.youtube-nocookie.com/embed/${elt.dataset.youtube}?autoplay=1`;
                    popin.classList.add('js-open');
                });
            });
        }

        if(popin) {
            popin.querySelector('.youtube__close').addEventListener('click', () => {
                popin.querySelector('.youtube__iframe iframe').src = '';
                popin.classList.remove('js-open');
            });
    
            popin.querySelector('.youtube__overlay').addEventListener('click', () => {
                popin.querySelector('.youtube__iframe iframe').src = '';
                popin.classList.remove('js-open');
            });
        }
    }

    sound() {
        const elts = document.querySelectorAll('[data-sound]');
        const popin = document.querySelector('.sound');

        if (elts.length > 0 && popin) {
            elts.forEach((elt) => {
                elt.addEventListener('click', () => {
                    popin.querySelector('.sound__player audio').src = elt.dataset.sound;
                    popin.classList.add('js-open');
                });
            });
        }

        if(popin) {
            popin.querySelector('.sound__close').addEventListener('click', () => {
                popin.querySelector('.sound__player audio').src = '';
                popin.classList.remove('js-open');
            });
    
            popin.querySelector('.sound__overlay').addEventListener('click', () => {
                popin.querySelector('.sound__player audio').src = '';
                popin.classList.remove('js-open');
            });
        }
    }

    video() {
        const elts = document.querySelectorAll('[data-video]');
        const popin = document.querySelector('.videopopin');

        if (elts.length > 0 && popin) {
            elts.forEach((elt) => {
                elt.addEventListener('click', () => {
                    popin.querySelector('.videopopin__player video').src = elt.dataset.video;
                    popin.classList.add('js-open');
                });
            });
        }

        if(popin) {
            popin.querySelector('.videopopin__close').addEventListener('click', () => {
                popin.querySelector('.videopopin__player video').src = '';
                popin.classList.remove('js-open');
            });
    
            popin.querySelector('.videopopin__overlay').addEventListener('click', () => {
                popin.querySelector('.videopopin__player video').src = '';
                popin.classList.remove('js-open');
            });
        }
    }
}